import { useTranslation } from 'react-i18next'
import { MutationResult } from '@apollo/client'
import { Collapse } from '@mui/material'
import { MColor, MFlexBlock, MFlexItem, MText } from '@mprise/react-ui'

export const MutationErrorMessage = ({
  mutation,
  overrideError,
}: {
  mutation: MutationResult | Array<MutationResult>
  overrideError?: string
}) => {
  mutation = Array.isArray(mutation) ? mutation : [mutation]

  const { t } = useTranslation()

  const firstWithAnIssue = mutation.find(x => !x.loading && !!x.error)
  return (
    <Collapse in={!!firstWithAnIssue} unmountOnExit timeout={100}>
      <MFlexBlock bgColor={MColor.medium} variant='rounded' margin={0} padding={2}>
        <MFlexItem grow={1}>
          <MText block textVariant='content bold'>
            {t('SUBMITTING_PROBLEM_OCCURED')}
          </MText>
          <MText block textVariant='small'>
            {overrideError ? overrideError : firstWithAnIssue?.error?.message ?? ''}
          </MText>
        </MFlexItem>
      </MFlexBlock>
    </Collapse>
  )
}
