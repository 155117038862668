import { useHistory } from '../../shared/use-history'
import { useTranslation } from 'react-i18next'
import { BarcodeInput, useBarcodeInput } from '../../barcode/input'
import { CurrentCompanyContainer } from '../../context/current-company'
import { DefaultResourceContainer } from '../../context/default-resource'
import { Card } from '../../mprise-light/card'
import { Field } from '../../mprise-light/field'
import { Form } from '../../mprise-light/form'
import { PageHeader } from '../../mprise-light/header'
import { Section } from '../../mprise-light/section'
import { MissingResourceSettingPage } from '../../shared/missing-setting-page'
import { useState } from 'react'
import { GET_TRACKING_ID } from '../../gql/trackingIds'
import { useLazyQuery } from '@apollo/client'
import { FlashAlerts } from '../../mprise-light/flash-alerts'
import { MAudio, MFlexBlock, MFlexItem } from '@mprise/react-ui'
import { Collapse, IconButton } from '@mui/material'
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'
import { TrackingIdDetailsCard } from './trackingIdDetailCard'
import { TrackingIdHistory } from './trackingIdHistory'
import { GET_TRID_HISTORY } from '../../gql/trackingIdHistory'

export const TrackingIdDetailsRoute = () => {
  const { t } = useTranslation()
  const h = useHistory()

  const { current: currentCompany } = CurrentCompanyContainer.useCurrent()
  const { default: currentResource } = DefaultResourceContainer.useDefault()
  const alerts = FlashAlerts.useAlert()
  const [text, setText] = useState<string>('')
  const [openDetails, setOpenDetails] = useState(false)
  const [openHistoryDetails, setOpenHistoryDetails] = useState(false)
  const trackingIdCode = useBarcodeInput('trackingIdCode')

  const [getTracking, { data: trackingData }] = useLazyQuery(GET_TRACKING_ID)
  const [getTrackingHistory, { data: trackingDataHistory }] = useLazyQuery(GET_TRID_HISTORY)
  const [showTrackingData, setShowTrackingData] = useState(false)
  const handleOpenDetails = (e: any) => {
    setOpenDetails(!openDetails)
  }
  const handleOpenHistoryDetails = (e: any) => {
    setOpenHistoryDetails(!openHistoryDetails)
  }

  const handleInputAction = async (inputText: string) => {
    try {
      if (inputText) {
        setText(inputText)

        await getTracking({
          variables: {
            filter: {
              companyId: +currentCompany!.id,
              code: inputText,
            },
          },
          onError: () => {
            MAudio.scanError()
            alerts.push(t('NOTIFICATION_ERROR_LOADING_TRACKINGID'), 'error')
          },
          fetchPolicy: 'no-cache',
        })

        await getTrackingHistory({
          variables: {
            company: +currentCompany!.id,
            trackingCode: inputText,
          },
          fetchPolicy: 'no-cache',
        })
        MAudio.scanSuccess()
        setShowTrackingData(true)
      }
    } catch (ex) {
      MAudio.scanError()
      alerts.push(Object(ex).message ?? String(ex), `error`)
    }
  }

  const handleReset = () => {
    setText(``)
    setTimeout(() => {
      h.push(`/`)
      h.push(`/trackingIdDetails`)
    }, 0)
    setShowTrackingData(false)
  }

  const handleCancel = () => {
    h.push('/')
  }

  if (!currentResource) {
    return <MissingResourceSettingPage pageTitle={t('TITLE_TRACKINGID_DETAILS')} />
  }

  return (
    <>
      <div className='box'>
        <PageHeader title={t('TITLE_TRACKINGID_DETAILS')} onCancel={handleCancel} onClear={handleReset} />
        <Section style={{ flex: '0 1 auto' }}>
          <Card>
            <Form>
              <Field label={t('FIELD_TRACKING_ID_CODE')}>
                <BarcodeInput
                  api={trackingIdCode}
                  autoFocus
                  text={text}
                  onChange={setText}
                  onSubmit={handleInputAction}
                />
              </Field>
            </Form>
          </Card>
        </Section>
        {trackingData && showTrackingData && (
          <Section>
            <MFlexBlock vertical padding={2}>
              <SectionHeader title={'Tracking Id Details'} open={openDetails} handleOpen={handleOpenDetails} />
              <Collapse in={openDetails}>
                <TrackingIdDetailsCard tracking={trackingData} />
              </Collapse>
            </MFlexBlock>
          </Section>
        )}
        {trackingData && trackingDataHistory && showTrackingData && (
          <Section>
            <MFlexBlock vertical padding={2}>
              <SectionHeader
                title={'Tracking Id History'}
                open={openHistoryDetails}
                handleOpen={handleOpenHistoryDetails}
              />
              <Collapse in={openHistoryDetails}>
                <TrackingIdHistory trackingIdHistory={trackingDataHistory} />
              </Collapse>
            </MFlexBlock>
          </Section>
        )}
      </div>
    </>
  )
}
const SectionHeader = ({ title, open, handleOpen }: { title: string; open: boolean; handleOpen: (e: any) => void }) => {
  const { t } = useTranslation()

  return (
    <div className='section-header' onClick={handleOpen}>
      <MFlexItem shrink={0}>
        <IconButton>
          <Icon path={mdiChevronRight} size={1} rotate={open ? 90 : 0} style={{ transition: `transform 0.3s` }} />
        </IconButton>
      </MFlexItem>
      <div style={{ flexGrow: 1 }}>
        <div>{title}</div>
      </div>
    </div>
  )
}
